import React from "react";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/integration/Hero";
import Progress from "../../sections/integration/Progress";
import Dedicated from "../../sections/integration/Dedicated";
import Wave from "../../sections/common/Wave";
import WaveReverse from "../../sections/common/WaveReverse";
import Commit from "../../sections/common/Commit";
import SEO from '../../components/SEO';
import metaImg from '../../assets/image/metaimg/integrate.jpg';

const IntegrationPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          footerStyle: "digma",
        }}>
        <SEO
          title="Integration - Simply copy and paste the code onto your website"
          description="Integrate your Interactive Video Player to any Platform"
          image={metaImg}
        />             
        <Hero />
        <Wave color="#F7F9FC" />         
        <Progress className="grey pb-lg-21 border-default-color-1" />
        <WaveReverse color="#fff" background="#F7F9FC" />
        <Dedicated className="pb-lg-21" />  
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>   
      </PageWrapper>
    </>
  );
};
export default IntegrationPage;
