import React from "react";
import { Link } from "gatsby";

import imgM from "../../assets/image/hero/webpage.jpg";

const Progress = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest} style={{position: "relative", top:"-1px", zIndex:"100"}}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-9 col-xs-10">
              <div className="text-center pt-15 pt-lg-15 pb-13 pb-lg-12 pr-lg-10 pr-xl-0">
              <h2 className="font-size-10 mb-5">2. Dedicated funnel page</h2>
              <p className="font-size-7 mb-0">
                Don't have a website? We can build you a page
              </p>
              </div>
            </div>
          </div>
          {/* <!-- Section Title --> */}
          {/* <!-- Progress Items --> */}
          <div className="row">
            {/* Right */}
            <div
              className="col-xl-6 col-lg-6 col-md-5 col-xs-8"
            >
              <div className="content-image-group-3 mb-17 mb-lg-0 mr-xl-16">
                <div className="img-1">
                  <img className="w-100" src={imgM} alt="Digma Interactive Integration" />
                </div>
              </div>
            </div>
            {/* Right */}
            {/* Left */}
            <div
              className="col-lg-6 col-md-7 col-xs-10"
            >
              <div className="mr-lg-10 mr-xl-25">
                <p className="heading-default-color font-size-6 mb-10">
                  If you don't have a website, blog or you simply just want a dedicated webpage 
                  for your interactive content, Digma can help you with that.
                </p>
                <p className="heading-default-color font-size-6 mb-11">
                  Each Interactive video, image and form has its  own dedicated landing page. You can brand your page with your company logo and any copy you want on the page.
                </p>
                               
                <div className="btn-group mb-12">
                  <Link className="btn btn-dodger-blue-2 rounded-5" to={"/contact"}>
                    Let's Get Started
                  </Link>
                </div>
              </div>
            </div>
            {/* Left End */}
          </div>
        
          {/* <!-- End Progress Items --> */}
        </div>
      </div>
    </>
  );
};

export default Progress;
